import axios from 'axios'

export default axios.create({
    baseURL: "https://flixzone3.onrender.com"
    // baseURL: 'https://5b3b-142-127-162-109.ngrok-free.app',
    // headers: {'ngrok-skip-browser-warning': "true"}
});

//  https://c620-142-127-162-109.ngrok-free.app 
// https://93c0-69-157-24-62.ngrok-free.app
//  https://4bee-69-157-24-62.ngrok-free.app 
// https://9c96-103-106-239-104.ap.ngrok.io/